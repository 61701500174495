<template>
  <div>
    <b-card
      no-body
      class="custom-border-top-1px"
    >
      <b-card-header>
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >{{ title || 'YÊU CẦU TRUY CẬP' }}
        </b-badge>
      </b-card-header>
      <b-table
        ref="table-loginRequestList"
        class="position-relative px-2"
        responsive
        primary-key="id"
        show-empty
        bordered
        small
        empty-text="Không có kết quả nào"
        :items="loginRequestList"
        :fields="tableColumnsLoginRequestList"
      >
        <template #cell(user.fullname)="data">
          <router-link
            class="w-50"
            :to="'/user/' + data.item.user_id + '/detail'"
          >
            {{ data.item.user && data.item.user.fullname }}
          </router-link>
        </template>

        <template #cell(created_at)="data">
          <div class="text-right">
            {{ formatDate(data.item.created_at) }}
          </div>
        </template>

        <template #cell(status)="data">
          <b-form-checkbox
            v-if="$ability().can('accept-device')"
            :key="'checkbox-accept-device-' + hashAcceptDevice[data.item.id] + refreshKey"
            v-model="hashAcceptDevice[data.item.id]"
            name="check-button"
            switch
            @change="isChecked => handleToggleStatusCheckbox(isChecked, data.item)"
          >
            <b-badge
              :variant="data.item.status === 'accepted' ? 'light-primary' : 'light-warning'"
              class="font-small-2"
            >{{ getTextByStatus(data.item.status) }}
            </b-badge>
          </b-form-checkbox>
          <b-badge
            v-else
            :variant="data.item.status === 'accepted' ? 'light-primary' : 'light-warning'"
            class="font-small-2"
          >{{ getTextByStatus(data.item.status) }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <div
            v-if="$ability().can('accept-device')"
            class="d-flex justify-content-end"
          >
            <b-button
              v-if="data.item.status === 'new'"
              variant="primary"
              size="sm"
              class="p-50 mr-1"
              @click="openModalAcceptDevice(data.item)"
            >
              <feather-icon
                icon="CheckIcon"
              />
            </b-button>
            <b-button
              variant="danger"
              size="sm"
              class="p-50"
              @click="openModalDeleteDevice(data.item)"
            >
              <feather-icon
                icon="XIcon"
              />
            </b-button>
          </div>
        </template>
      </b-table>

      <b-col
        cols="12"
        class="mb-3"
      >
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1 mb-md-0"
          >
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>mỗi trang</label>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-card>

    <b-modal
      id="modal-accept-device"
      ref="modal-accept-device"
      cancel-title="Hủy"
      ok-title="Cho phép"
      size="lg"
      :no-close-on-backdrop="true"
      centered
      header-text-variant="primary"
      :title="'Cho phép thiết bị truy cập'"
      @ok="handleAcceptDevice"
    >
      <b-card-text>
        <div>Tên nhân viên: <span
          class="text-primary font-weight-bolder cursor-pointer"
          @click="gotoUserDetail(currentDevice.user_id)"
        >{{ currentDevice.user && currentDevice.user.fullname }}</span></div>
        <br>
        <div>Tên thiết bị: <span class="text-muted">{{ currentDevice.device }}</span></div>
        <br>
        <div>Thời gian: <span class="text-success">{{ formatDate(currentDevice.created_at, true) }}</span></div>
        <br>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-reject-device"
      ref="modal-reject-device"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      size="lg"
      :no-close-on-backdrop="true"
      centered
      header-text-variant="warning"
      :title="'Chặn thiết bị truy cập'"
      @ok="handleRejectDevice"
    >
      <b-card-text>
        <div>Tên nhân viên: <span
          class="text-primary font-weight-bolder cursor-pointer"
          @click="gotoUserDetail(currentDevice.user_id)"
        >{{ currentDevice.user && currentDevice.user.fullname }}</span></div>
        <br>
        <div>Tên thiết bị: <span class="text-muted">{{ currentDevice.device }}</span></div>
        <br>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-delete-device"
      ref="modal-delete-device"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      size="lg"
      :no-close-on-backdrop="true"
      centered
      header-text-variant="danger"
      :title="'Xóa thiết bị truy cập'"
      @ok="handleDeleteDevice"
    >
      <b-card-text>
        <div>Tên nhân viên: <span
          class="text-primary font-weight-bolder cursor-pointer"
          @click="gotoUserDetail(currentDevice.user_id)"
        >{{ currentDevice.user && currentDevice.user.fullname }}</span></div>
        <br>
        <div>Tên thiết bị: <span class="text-muted">{{ currentDevice.device }}</span></div>
        <br>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BBadge, BButton, BCard, BCardHeader, BCardText, BCol, BFormCheckbox, BPagination, BRow, BTable, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import userStoreModule from '@/views/settings/users/userStoreModule'
import { onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import useLoginRequest from './useLoginRequest'

export default {
  components: {
    BFormCheckbox,
    BCardText,
    BCard,
    BCardHeader,
    BBadge,
    BTable,
    BButton,
    BRow,
    BCol,
    BPagination,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    title: {
      type: String,
      default: 'YÊU CẦU TRUY CẬP',
    },
    userDeviceId: {
      type: String,
      default: '',
    },
  },
  watch: {
    userDeviceId(n) {
      this.filterDeviceByUserId = n
    },
  },
  created() {
    this.fetchLoginRequestList()
  },
  methods: {
    handleToggleStatusCheckbox(isChecked, device) {
      if (isChecked) {
        this.openModalAcceptDevice(device)
      } else {
        this.openModalRejectDevice(device)
      }
      this.updateHashAcceptDevice()
    },
    gotoUserDetail(id) {
      this.$router.push({ name: 'user-detail', params: { id } })
    },
    openModalAcceptDevice(device) {
      this.currentDevice = device
      this.$refs['modal-accept-device'].show()
    },
    openModalRejectDevice(device) {
      this.currentDevice = device
      this.$refs['modal-reject-device'].show()
    },
    openModalDeleteDevice(device) {
      this.currentDevice = device
      this.$refs['modal-delete-device'].show()
    },
    handleAcceptDevice() {
      this.$call(this.acceptDevice(this.currentDevice.id), true)
    },
    handleRejectDevice() {
      this.$call(this.rejectDevice(this.currentDevice.id), true)
    },
    handleDeleteDevice() {
      this.$call(this.deleteDevice(this.currentDevice.id), true)
    },
    getTextByStatus(status) {
      if (status === 'accepted') return 'Đang cho phép'
      if (status === 'new') return 'Mới'
      if (status === 'rejected') return 'Đang chặn'
      return '_'
    },
  },
  setup() {
    const NOTIFICATION_STORE_MODULE_NAME = 'loginRequest'
    if (!store.hasModule(NOTIFICATION_STORE_MODULE_NAME)) store.registerModule(NOTIFICATION_STORE_MODULE_NAME, userStoreModule)
    onUnmounted(() => {
      if (store.hasModule(NOTIFICATION_STORE_MODULE_NAME)) store.unregisterModule(NOTIFICATION_STORE_MODULE_NAME)
    })

    return {
      ...useLoginRequest(),
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.card-body .card-body {
  padding: .5rem;
}
</style>
