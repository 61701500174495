<template>
  <div class="upload-wrapper">
    <vue-dropzone
      :id="id"
      :ref="id"
      :options="dropzoneOptions"
      :class="!files.length? 'no-files' : ''"
      @vdropzone-file-added="handleFileAdded"
      @vdropzone-duplicate-file="handleDuplicateFile"
      @vdropzone-error="handleErrorFile"
      @vdropzone-success-multiple="handleSuccessMultiple"
      @vdropzone-removed-file="handleRemovedFile"
      @vdropzone-queue-complete="allDone"
      @vdropzone-sending="handleSending"
    />
  </div>
</template>

<script>
import { VBToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vue2Dropzone from 'vue2-dropzone'
import { computed, ref } from '@vue/composition-api'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    id: {
      type: String,
      default: 'dropzone',
    },
    methodName: {
      type: String,
      default: 'uploadFile',
    },
    files: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => {
      },
    },
  },
  watch: {
    files() {
      this.loadPictures()
    },
  },
  methods: {
    handleSending(file, xhr, formData) {
      formData.append('method', this.methodName)
    },
    loadPictures() {
      if (this.files && this.files.length) {
        this.files.forEach(f => {
          if (this.filesNameExisted[f.name]) return // file existed
          this.filesNameExisted[f.name] = true
          this.$refs[this.id || 'uploads'].manuallyAddFile(f, f.origin)
          this.$refs[this.id || 'uploads'].dropzone.emit('thumbnail', f, f.origin)
          this.$refs[this.id || 'uploads'].dropzone.emit('complete', f)
        })
      } else {
        this.$refs[this.id || 'uploads'].removeAllFiles()
      }
    },
    handleFileAdded(file) {
      this.filesNameExisted[file.name] = true
    },
    handleDuplicateFile(file) {
      this.$toastr(`File [ ${file.name} ] đã được tải lên trước đó!`, 'warning')
    },
    handleErrorFile(file, message) {
      this.$toastr(message, 'danger')
    },
    handleSuccessMultiple(files, response) {
      const f = response.map((r, idx) => ({
        ...r,
        name: files[idx].name,
      }))
      this.$emit('file-uploaded', f)
    },
    handleRemovedFile(response) {
      this.$emit('file-removed', response)
    },
    allDone() {
      this.$emit('all-done')
    },
  },
  setup(props) {
    const url = $themeConfig.app.urlUpload
    const filesNameExisted = ref({})
    const dropzoneOptions = computed({
      get: () => ({
        url,
        thumbnailWidth: 250,
        addRemoveLinks: true,
        acceptedFiles: 'image/*,application/pdf,.docx',
        // maxFilesize: 5,
        uploadMultiple: true,
        dictDefaultMessage: `<span class="text-primary font-medium-3"><i class="vs-icon feather icon-upload-cloud" /> ${props.options?.textDisplay || 'CHỌN FILE'}</span>`,
        paramName: 'files',
        ...props.options,
      }),
      set: () => {},
    })
    return {
      url,
      filesNameExisted,
      dropzoneOptions,
    }
  },
}
</script>
<style lang="scss">
@import 'vue2-dropzone/dist/vue2Dropzone.min';
</style>
