import { ref, watch } from '@vue/composition-api'
import axiosIns from '@/libs/axios'
import http from '@/global/http'

export default function useNotificaiton() {
  const filterDeviceByUserId = ref()
  const loginRequestList = ref([])
  const currentDevice = ref({})

  const perPage = ref(50)
  const perPageOptions = [10, 25, 50, 100]
  const currentPage = ref(1)
  const total = ref(0)

  const hashAcceptDevice = ref({})

  const tableColumnsLoginRequestList = [
    {
      label: 'Nhân viên',
      key: 'user.fullname',
    },
    {
      label: 'Tên thiết bị',
      key: 'device',
    },
    {
      label: 'Thời gian',
      key: 'created_at',
    },
    {
      label: 'Trạng thái',
      key: 'status',
    },
    {
      label: '',
      key: 'actions',
    },
  ]

  const refreshKey = ref(0)

  const updateHashAcceptDevice = () => {
    loginRequestList.value.forEach(device => {
      hashAcceptDevice.value[device.id] = device.status === 'accepted'
    })
    refreshKey.value += 1
  }

  const fetchLoginRequestList = cb => axiosIns.get(`/api.user/device/all${http.buildQuery({
    perpage: perPage.value,
    page: currentPage.value - 1,
    user_id: filterDeviceByUserId.value,
  }) || ''}`).then(res => {
    loginRequestList.value = (res.data.data || []).reverse()
    updateHashAcceptDevice()
    total.value = res.data.total
    return cb && cb(res.data.data)
  })

  const acceptDevice = id => axiosIns.post(`/api.user/device/accept/${id}`).then(() => {
    loginRequestList.value.find(u => u.id === id).status = 'accepted'
    updateHashAcceptDevice()
  })
  const rejectDevice = id => axiosIns.post(`/api.user/device/reject/${id}`).then(() => {
    loginRequestList.value.find(u => u.id === id).status = 'rejected'
    updateHashAcceptDevice()
  })
  const deleteDevice = id => axiosIns.delete(`/api.user/device/${id}`).then(() => {
    const foundIdx = loginRequestList.value.findIndex(item => item.id === id)
    if (foundIdx !== -1) {
      loginRequestList.value.splice(foundIdx, 1)
      updateHashAcceptDevice()
    }
  })

  watch([currentPage, perPage, filterDeviceByUserId], () => {
    fetchLoginRequestList()
  })

  return {
    filterDeviceByUserId,
    currentDevice,
    perPage,
    perPageOptions,
    currentPage,
    total,
    loginRequestList,
    tableColumnsLoginRequestList,
    refreshKey,
    hashAcceptDevice,
    fetchLoginRequestList,
    acceptDevice,
    rejectDevice,
    deleteDevice,
    updateHashAcceptDevice,
  }
}
