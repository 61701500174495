<template>
  <div>
    <b-card no-body>
      <div class="card-header">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          THÔNG TIN CƠ BẢN
        </b-badge>
      </div>

      <div class="card-body">
        <div>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <div
                v-if="user.role"
                class="form-group-hor mb-1 font-medium-1"
              >
                <label>Phân quyền</label>
                <b-badge
                  variant="primary"
                  class="flex-grow-0"
                >
                  {{ ROLES.find(item => item.value === user.role).text }}
                </b-badge>
              </div>

              <div
                class="form-group-hor mb-1 font-medium-1"
              >
                <label>Chi nhánh</label>
                <b-badge
                  v-if="branches.find(item => item.id === user.branch_id)"
                  variant="primary"
                  class="flex-grow-0"
                >
                  {{ branches.find(item => item.id.toString() === user.branch_id.toString()).name }}
                </b-badge>
              </div>

              <div
                v-if="user.role"
                class="form-group-hor mb-1 font-medium-1"
              >
                <label>Email</label>
                <div class="flex-grow-0 text-primary">
                  {{ user.email }}
                </div>
              </div>

              <dynamic-form
                :id="'user-form-hoten'"
                :value="user.fullname"
                :type="'text'"
                :label="'Họ tên'"
                placeholder="Họ tên"
                required="required"
                :class="'form-group-hor'"
                @update-value="val => user.fullname = val"
              />

              <dynamic-form
                :id="'user-form-phone'"
                :type="'text'"
                :value="user.phone"
                :label="'Số điện thoại'"
                placeholder="Số điện thoại"
                required="phone"
                :class="'form-group-hor'"
                @update-value="val => user.phone = val"
              />

              <div>
                <small>(*) Được thấy thông tin chủ nhà của
                  <template v-if="user.is_limit_price">
                    những sản phẩm có giá <strong>nhỏ hơn</strong> <strong class="text-primary">{{ formatMoney(user.limit_price) }}</strong>
                  </template>
                  <template v-else>
                    <strong>tất cả</strong> các sản phẩm
                  </template>
                </small>
              </div>

              <div v-if="user.is_access_office">
                <small>(*)
                  <strong class="text-primary">Được xem</strong>  mục <strong>Văn Phòng</strong>
                </small>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="3"
            >
              <div class="d-flex flex-column h-100">
                <label>Ảnh đại diện:</label>
                <div class="flex-grow-1 mb-1">
                  <b-avatar
                    size="100"
                    class="avatar-border-2 box-shadow-1 badge-minimal user-avatar"
                    :src="getUrlFile(user.avatar, today)"
                    rounded
                    variant="light-primary"
                    alt="Ảnh đại diện"
                  >
                    <feather-icon
                      v-if="!user.avatar"
                      icon="UserIcon"
                      size="22"
                    />
                  </b-avatar>
                </div>
                <div class="mb-1">
                  <uploads
                    id="uploads"
                    ref="uploads"
                    class="hidden-all-item upload-image"
                    :files="files"
                    :options="{textDisplay: 'Tải ảnh lên'}"
                    :method-name="'uploadAvatar'"
                    @file-uploaded="handleUploaded"
                    @file-removed="handleRemoved"
                  />
                </div>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="d-flex flex-column mb-1"
            >
              <div class="form-group-hor mb-1 font-medium-1">
                <label>Ngày tạo:</label>
                <div class="text-right">
                  <strong>{{ formatDate(user.created_at) }}</strong>
                </div>
              </div>

              <div class="flex-grow-1">
                <b-button
                  variant="outline-primary"
                  class="mb-1 w-100"
                  @click="openModalChangePassword"
                >
                  Đổi mật khẩu
                </b-button>
              </div>

              <div class="d-flex gap-1 justify-content-end flex-wrap">
                <b-button
                  variant="primary"
                  class="flex-grow-1"
                  @click="handleUpdateUser"
                >
                  <feather-icon icon="SaveIcon" />
                  Lưu
                </b-button>
              </div>

            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>

    <b-card no-body>
      <div class="card-header">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          THIẾT LẬP THÔNG BÁO
        </b-badge>
      </div>

      <div class="card-body">
        <div>
          <dynamic-form
            :id="'product-form-tinhtrang'"
            :value="user.client_type_config"
            :type="'multi-select'"
            :label="'Tình trạng'"
            multiple="multiple"
            :select-default="false"
            :class="'form-group-hor'"
            :options="STATUS_TYPE_CUSTOMER"
            @update-value="val => user.client_type_config = val"
          />
          <div class="d-flex justify-content-end">

            <div class="d-flex gap-1 justify-content-end flex-wrap">
              <b-button
                variant="primary"
                class="flex-grow-1"
                @click="handleUpdateUser"
              >
                <feather-icon icon="SaveIcon" />
                Lưu
              </b-button>
            </div>

          </div>
        </div>
      </div></b-card>

    <LoginRequest
      :user-device-id="user.id.toString()"
      :title="'THIẾT BỊ TRUY CẬP'"
    />

    <b-card
      no-body
    >
      <div class="card-header">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          BÁO CÁO
        </b-badge>
        <div class="d-flex align-items-center">
          <v-select
            v-model="monthFilter"
            class="select-size-sm mr-25"
            label="name"
            :options="MONTH_OPTIONS"
            :searchable="false"
          />
          <v-select
            v-model="yearFilter"
            class="select-size-sm"
            label="name"
            :options="YEAR_OPTIONS"
            :searchable="false"
          />
        </div>
      </div>

      <div class="card-body">
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex w-250 justify-content-between">
              <span class="mr-1">Nguồn nhà phố tìm về:</span>
              <strong
                v-if="userReportData"
                class="text-primary"
              >{{ userReportData.sum_source_townhouse }}</strong>
            </div>

            <div
              v-if="userData.role !== 'user'"
              class="d-flex w-250 justify-content-between"
            >
              <span class="mr-1">Nhập nhà phố</span>
              <strong
                v-if="userReportData"
                class="text-primary"
              >{{ userReportData.sum_user_townhouse }}</strong>
            </div>

            <div
              v-if="ability().can('show-total_sum_user_office_price', 'Report')"
              class="d-flex w-250 justify-content-between"
            >
              <span class="mr-1" />
              <div>
                <span
                  v-if="userReportData"
                >Số căn &lt; 100tr: <strong>{{ userReportData.total_sum_user_office_price_high }}</strong></span>
                <br>
                <span
                  v-if="userReportData"
                >Số căn &ge; 100tr: <strong>{{ userReportData.total_sum_user_office_price_low }}</strong></span>
              </div>
            </div>

            <div
              v-if="userData.role !== 'user'"
              class="d-flex w-250 justify-content-between"
            >
              <span class="mr-1">Nhập văn phòng:</span>
              <strong
                v-if="userReportData"
                class="text-primary"
              >{{ userReportData.sum_user_office }}</strong>
            </div>

            <div class="d-flex w-250 justify-content-between">
              <span class="mr-1">Bán:</span>
              <strong
                v-if="userReportData"
                class="text-primary"
              >{{ userReportData.total_sum_user_townhouse_sell }}</strong>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div
              v-if="userData.role !== 'supporter'"
              class="d-flex w-250 justify-content-between text-primary cursor-pointer"
              @click="goListCustomerCorner({user_id: user.id, monthFilter: monthFilter, yearFilter: yearFilter, username: user.fullname})"
            >
              <span class="mr-1">Số khách nhà phố:</span>
              <strong
                v-if="userReportData"
                class="text-primary"
              >{{ userReportData.sum_client_townhouse }}</strong>
            </div>

            <div
              v-if="userData.role !== 'supporter'"
              class="d-flex w-250 justify-content-between"
            >
              <span class="mr-1">Số khách văn phòng:</span>
              <strong
                v-if="userReportData"
                class="text-primary"
              >{{ userReportData.sum_client_office }}</strong>
            </div>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <div>
              <label class="text-primary font-weight-bolder">Sản phẩm nhà phố</label>
              <hr class="mt-0">
              <vue-apex-charts
                :key="'user-chart-product-townhouse-' +keyRefreshComponent"
                :options="productChartTownhouse.options"
                :series="productChartTownhouse.series"
              />
            </div>
            <div>
              <label class="text-primary font-weight-bolder">Sản phẩm văn phòng</label>
              <hr class="mt-0">
              <vue-apex-charts
                :key="'user-chart-product-office-' +keyRefreshComponent"
                :options="productChartOffice.options"
                :series="productChartOffice.series"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div>
              <label class="text-primary font-weight-bolder">Khách hàng nhà phố</label>
              <hr class="mt-0">
              <vue-apex-charts
                :key="'user-chart-customer-townhouse-' +keyRefreshComponent"
                :options="customerChartTownhouse.options"
                :series="customerChartTownhouse.series"
              />
            </div>
            <div>
              <label class="text-primary font-weight-bolder">Khách hàng văn phòng</label>
              <hr class="mt-0">
              <vue-apex-charts
                :key="'user-chart-customer-townhouse-' +keyRefreshComponent"
                :options="customerChartOffice.options"
                :series="customerChartOffice.series"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <modal-change-password
      id="modal-admin-change-password"
      ref="modal-admin-change-password"
      modal-mode="admin-change-password"
      :user="user"
    />
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BButton, BCard, BCol, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import { onUnmounted } from '@vue/composition-api'
import useUserDetail from '@/views/user/detail/useUserDetail'
import store from '@/store'
import userStoreModule from '@/views/user/userStoreModule'
import ModalChangePassword from '@/views/settings/users/list/ModalChangePassword.vue'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import Uploads from '@core/components/uploads/Uploads.vue'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'
import { getUserData } from '@/auth/utils'
import http from '@/global/http'
import axiosIns from '@/libs/axios'
import LoginRequest from '@/views/login-request/LoginRequest.vue'

export default {
  components: {
    LoginRequest,
    VueApexCharts,
    vSelect,
    BCard,
    BButton,
    BRow,
    BCol,
    ModalChangePassword,
    DynamicForm,
    BBadge,
    Uploads,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ability,
      subject,
    }
  },
  computed: {
    chartOptionsProduct() {
      return this.chartOptions
    },
    chartOptionsCustomer() {
      return this.chartOptions
    },
  },
  watch: {
    userChartData(n) {
      if (!n) return
      const p = n.product
      const c = n.client
      const co = n.client_office
      const po = n.product_office
      // eslint-disable-next-line no-nested-ternary
      const xLabelFormat = !this.yearFilter ? 'yyyy' : this.monthFilter ? 'dd-MM' : 'MM-yy'

      this.customerChartTownhouse.series[0].data = c.map(item => parseInt(item.total_product, 10))
      this.customerChartTownhouse.options.xaxis.categories = [...c.map(item => item.time)]
      this.customerChartTownhouse.options.xaxis.labels.format = xLabelFormat
      this.keyRefreshComponent += 1

      this.productChartTownhouse.series[0].data = p.map(item => parseInt(item.total_product, 10))
      this.productChartTownhouse.options.xaxis.categories = p.map(item => item.time)
      this.productChartTownhouse.options.xaxis.labels.format = xLabelFormat
      this.keyRefreshComponent += 1

      this.customerChartOffice.series[0].data = co.map(item => parseInt(item.total_product, 10))
      this.customerChartOffice.options.xaxis.categories = [...co.map(item => item.time)]
      this.customerChartOffice.options.xaxis.labels.format = xLabelFormat
      this.keyRefreshComponent += 1

      this.productChartOffice.series[0].data = po.map(item => parseInt(item.total_product, 10))
      this.productChartOffice.options.xaxis.categories = po.map(item => item.time)
      this.productChartOffice.options.xaxis.labels.format = xLabelFormat
      this.keyRefreshComponent += 1
    },
  },
  created() {
    this.isReportMe = true
    const userData = getUserData()
    Object.assign(this.user, userData)
    this.fetchReportUser()
    this.$store.dispatch('app/fetchBranches')
  },
  methods: {
    goListCustomerCorner(filter) {
      this.$router.push({ name: 'customer-corner-list', query: { filter: JSON.stringify(filter) } })
    },
    handleUploaded(files = []) {
      this.files.push(...files)
    },
    handleRemoved(file) {
      const foundIdx = this.files.findIndex(f => f.name === file.name)
      if (foundIdx !== -1) {
        this.files.splice(foundIdx, 1)
      }
    },
    openModalConfirmDeleteUser() {
      this.$refs['modal-confirm-delete-user'].show()
    },
    openModalChangePassword() {
      this.$refs['modal-admin-change-password'].$refs['btn-open-modal-change-password'].click()
    },
    handleDeleteUser() {
      this.$call(this.deleteUser(this.user.id, () => {
        this.$router.push({ name: 'user-list' })
      }), true)
    },
    handleUpdateUser() {
      this.$call(http.handle(axiosIns.put('/api.user/me', this.user), res => {
        Object.assign(this.user, res.data)
        localStorage.setItem('userData', JSON.stringify(this.user))
      }), true)
    },
    handleCreateUser() {
    },
  },
  setup() {
    const USER_STORE_MODULE_NAME = 'user'
    if (!store.hasModule(USER_STORE_MODULE_NAME)) store.registerModule(USER_STORE_MODULE_NAME, userStoreModule)
    onUnmounted(() => {
      if (store.hasModule(USER_STORE_MODULE_NAME)) store.unregisterModule(USER_STORE_MODULE_NAME)
    })

    return {
      ...useUserDetail(),
    }
  },
}
</script>

<style>
</style>
